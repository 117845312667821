<template>
  <v-card
    v-if="userProfile.occupation_sector.sector.name === 'MARKETING'"
  >
    <div
      class="d-flex align-center justify-space-between px-5 mb-8 pt-8"
      style=" width: 100%;"
    >
      <h3
        class="pl-0"
        style="font-weight: 600;"
      >
        Relatórios das solicitações
      </h3>
      <div
        class="d-flex"
        style="gap: 20px;"
      >
        <!-- v-if="isFiltered" -->
        <v-btn
          class="me-3"
          outlined
          color="warning"
          :disabled="isLoadingExport"
          @click="exportCsv()"
        >
          <div
            v-if="!isLoadingExport"
            class=" d-flex items-center align-center"
            style=" gap: 10px;"
          >
            <span class="button-text-logs">
              EXPORTAR
            </span>
            <v-img
              height="17"
              width="20"
              :src="mdiTrayArrowDown"
            ></v-img>
          </div>
          <v-progress-circular
            v-else
            color="warning"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
        <v-btn
          class=""
          outlined
          :color="filterButtonColor"
          @click="openModalFilterSolicitationsReport"
        >
          <v-icon
            size="22"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span class="button-text-actions">FILTRAR</span>
        </v-btn>
        <v-btn
          v-if="isFiltered"
          outlined
          color="error"
          @click="clearFilters"
        >
          <v-icon
            size="22"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span class="button-text-actions">LIMPAR</span>
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="listOfFilteredItems"
      :loading="loadingTable"
      loading-text="Carregando dados..."
      class="text-no-wrap"
    >
      <template v-slot:item.slm="{ item }">
        <span style="color: #9A62FD; font-weight: 500; font-size: 14px;">
          0{{ item.slm ? item.slm : '' }}
        </span>
      </template>
      <template v-slot:item.expected_delivery_date="{ item }">
        <span :class="{ 'toBeDefined': !item.expected_delivery_date }">
          {{ item.expected_delivery_date ? formatDate(item.expected_delivery_date) : 'A definir' }}
        </span>
      </template>
      <template v-slot:item.completion_date="{ item }">
        <span>
          {{ formatDate(item.completion_date) || '-' }}
        </span>
      </template>
      <template v-slot:item.requesters_expected_delivery_date="{ item }">
        <span>
          {{ formatDate(item.requesters_expected_delivery_date) || '-' }}
        </span>
      </template>
      <template v-slot:item.created_at="{ item }">
        <span>
          {{ formatToDayMonthYear(item.created_at) || '-' }}
        </span>
      </template>
      <template v-slot:item.category="{ item }">
        <span>
          {{ item.category ? item.category : '-' }}
        </span>
      </template>
      <template v-slot:item.days_between_dates="{ item }">
        <span v-if="item.status === 'FINALIZADA'">
          {{ item.days_between_dates && item.status === 'FINALIZADA' ? `${item.days_between_dates} DIAS` : 'RESOLVIDA NO MESMO DIA' }}
        </span>
        <span v-else>
          {{ !item.days_between_dates ? 'Ainda não finalizada' : '-' }}
        </span>
      </template>
      <template v-slot:item.assigned_to_user.name="{ item }">
        <v-tooltip
          v-if="item && item.assigned_to_user && item.assigned_to_user.name"
          top
          color="white"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              color="error"
              size="30"
              class="v-avatar-light-bg success--text"
              v-on="on"
            >
              <v-img
                v-if="item && item.assigned_to_user && item.assigned_to_user.url_photo"
                :src="item.assigned_to_user.base64"
              />
              <span
                v-else
                class="v-avatar-light-bg success--text"
                style="font-size: 13px;"
              >
                {{ getInitials(item.assigned_to_user) }}
              </span>
            </v-avatar>
          </template>
          <span style="color: black;">
            {{ item && item.assigned_to_user && item.assigned_to_user.name ? item.assigned_to_user.name : '-' }}
          </span>
        </v-tooltip>
        <span v-else>-</span>
      </template>
      <template v-slot:item.third_party.name="{ item }">
        <v-tooltip
          v-if="item && item.third_party && item.third_party.name"
          top
          color="white"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              :color="item.third_party.avatar_code ? returnCharBasedOnCharCode(item.third_party.avatar_code).bgColor: '#E7E3FC'"
              size="30"
              v-on="on"
            >
              <v-icon
                v-if="item && item.third_party && item.third_party.avatar_code"
                size="20"
                :color="item.third_party.avatar_code ? returnCharBasedOnCharCode(item.third_party.avatar_code).avatarColor: '#E7E3FC'"
              >
                {{ item.third_party.avatar_code ? `${icons[returnCharBasedOnCharCode(item.third_party.avatar_code).icon]}` : '-' }}
              </v-icon>
              <span
                v-else
                class="v-avatar-light-bg success--text"
                style="font-size: 13px;"
              >
                {{ getInitials(item.third_party) }}
              </span>
            </v-avatar>
          </template>
          <span style="color: black;">
            {{ item && item.third_party && item.third_party.name ? `${item.third_party.name}` : '-' }}
          </span>
        </v-tooltip>
        <span v-else>-</span>
      </template>
      <template v-slot:item.third_party.third_party_activity.name="{ item }">
        <span v-if="item.third_party && item.third_party.third_party_activity">{{ item.third_party.third_party_activity.name }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.subject="{ item }">
        <div
          class="d-flex"
          style="align-items: center; gap: 10px;"
        >
          <v-icon :color="returnIconOrColorBasedOnSubject('color', item.subject)">
            {{ returnIconOrColorBasedOnSubject('icon', item.subject) }}
          </v-icon>
          <span>
            {{ item.subject ? item.subject : '-' }}
          </span>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-alert
          class="badge px-5"
          :color="getStatusColor(item)"
          text
          style="border-radius: 50px;"
        >
          {{ item.status ? item.status : '' }}
        </v-alert>
      </template>
      <template
        v-slot:no-data
      >
        <div class="my-10">
          <NoDataInterfaceEditable
            title="Nenhum  registro encontrado!"
            subtitle="Por favor, verifique os filtros de pesquisa / Contacte a equipe de desenvolvimento."
          />
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showModalFilterReports"
      width="600px"
      transition="dialog-transition"
    >
      <ModalFilterReports
        ref="ModalFilterReports"
        :companies="companies"
        :requesters="requesters"
        :responsibles="responsibles"
        :sectors="sectors"
        :third-parties="thirdParties"
        :third-parties-activities="thirdPartiesActivities"
        @close="showModalFilterReports = false"
        @filterData="handleFilterData"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import NoDataInterfaceEditable from '@/components/charts/NoDataInterfaceEditable.vue'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/plugins/axios'

import mdiTrayArrowDown from '@/assets/images/svg/mdiTrayArrowDown.svg'
import { saveAs } from 'file-saver'

// eslint-disable-next-line import/no-cycle
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiAirballoonOutline, mdiAlienOutline, mdiAnchor,
  mdiBullhornOutline,
  mdiClose,
  mdiCropSquare,
  mdiDraw,
  mdiEyeOutline,
  mdiFilterVariant,
  mdiHandshakeOutline,
  mdiHomeOutline,
  mdiImageOutline,
  mdiMagnify,
  mdiMessageOutline,
  mdiMovieOpenOutline, mdiMusic,
  mdiPencil,
  mdiPlaylistPlus,
  mdiPrinter,
  mdiPuzzleOutline,
  mdiStarFourPointsOutline, mdiStarOutline,
  mdiVideoOutline,
  mdiVoicemail,
  mdiWeatherSunny,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalFilterReports from './modais/ModalFilterReports.vue'

const ExcelJS = require('exceljs')

export default {
  components: { NoDataInterfaceEditable, ModalFilterReports },
  mixins: [messages, formatters],
  data() {
    return {
      mdiTrayArrowDown,
      isFiltered: false,
      showModalFilterReports: false,
      isLoadingExport: false,
      loadingTable: false,
      listOfFilteredItems: [],
      responsibles: [],
      companies: [],
      requesters: [],
      sectors: [],
      thirdPartiesActivities: [],
      thirdParties: [],
      itemsTable: [],
      userProfile: {},
      icons: {
        mdiClose, mdiPlaylistPlus, mdiMagnify, mdiEyeOutline, mdiFilterVariant, mdiPuzzleOutline, mdiPencil, mdiWeatherSunny, mdiStarFourPointsOutline, mdiStarOutline, mdiHandshakeOutline, mdiPrinter, mdiAirballoonOutline, mdiAlienOutline, mdiAnchor, mdiHomeOutline, mdiMovieOpenOutline, mdiMusic,
      },
      headers: [
        {
          text: 'N° SLM',
          value: 'slm',
          sortable: true,
          align: 'left',
        },
        {
          text: 'DATA DA SOLICITAÇÃO',
          value: 'created_at',
          sortable: true,
          align: 'left',
        },
        {
          text: 'PRAZO DADO PELO SOLICITANTE',
          value: 'requesters_expected_delivery_date',
          sortable: true,
          align: 'left',
        },
        {
          text: 'FINALIZADA EM',
          value: 'completion_date',
          sortable: true,
          align: 'left',
        },
        {
          text: 'DURAÇÃO DA SOLICITAÇÃO',
          value: 'days_between_dates',
          sortable: true,
          align: 'center',
        },
        {
          text: 'PREVISÃO DE ENTREGA',
          value: 'expected_delivery_date',
          sortable: true,
          align: 'left',
        },
        {
          text: 'ASSUNTO',
          value: 'subject',
          sortable: true,
          align: 'left',
        },
        {
          text: 'CATEGORIA',
          value: 'category',
          sortable: true,
          align: 'left',
        },
        {
          text: 'SOLICITANTE',
          value: 'created_by_user.name',
          sortable: true,
          align: 'left',
        },
        {
          text: 'LOJA',
          value: 'created_by_user.company.fantasy_name',
          sortable: true,
          align: 'left',
        },
        {
          text: 'DEPARTAMENTO',
          value: 'created_by_user.occupation_sector.sector.name',
          sortable: true,
          align: 'left',
        },
        {
          text: 'TERCEIRO',
          value: 'third_party.name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'FUNÇÃO DO TERCEIRO',
          value: 'third_party.third_party_activity.name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'RESPONSÁVEL',
          value: 'assigned_to_user.name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: true,
          align: 'center',
        },
      ],
    }
  },
  computed: {
    filterButtonColor() {
      if (!this.isDark) {
        return this.isFiltered ? 'success' : 'black'
      }

      return this.isFiltered ? 'success' : 'white'
    },
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.getTableData()

    this.getResponsibles()
    this.getThirdParties()
    this.getCompanies()
    this.getRequesters()
    this.getSectors()
    this.handleGetThirdPartiesActivities()
  },
  methods: {
    handleFilterData(data) {
      this.listOfFilteredItems = data.data
      this.isFiltered = data.isFiltered
    },
    async getSectors() {
      try {
        const { data } = await axiosIns.get('/api/v1/records/sector/index')
        this.sectors = data.data
      } catch (error) {
        console.error('Erro ao buscar lista setores', error)
      }
    },
    async handleGetThirdPartiesActivities() {
      try {
        const { data } = await axiosIns.get('/api/v1/solicitations/marketing_third_parties_activity/index')
        this.thirdPartiesActivities = data.data
      } catch (error) {
        console.error('Erro ao buscar lista de atividades dos terceiros', error)
      }
    },
    async getResponsibles() {
      try {
        const { data } = await axiosIns.get('/api/v1/solicitations/marketing/get_responsibles_list')
        this.responsibles = data.data
      } catch (error) {
        console.error('Erro ao buscar lista de responsáveis', error)
      }
    },
    async getCompanies() {
      try {
        const { data } = await axiosIns.get('/api/v1/records/company/index?page=1')
        this.companies = data.data
      } catch (error) {
        console.error(' Erro ao buscar lista de empresas', error)
      }
    },
    async getRequesters() {
      try {
        const { data } = await axiosIns.get('/api/v1/solicitations/marketing/get_requesters')
        this.requesters = data.data
      } catch (error) {
        console.error('Erro ao buscar a lista de solicitantes', error)
      }
    },
    async getThirdParties() {
      try {
        const { data } = await axiosIns.get('/api/v1/solicitations/marketing_third_parties/index')
        this.thirdParties = data.data
      } catch (error) {
        console.error('Erro ao buscar lista de terceiros', error)
      }
    },
    clearFilters() {
      this.$refs.ModalFilterReports.handleClearFilter()
    },
    returnCharBasedOnCharCode(charCode) {
      const charMap = {
        CH1: { icon: 'mdiHandshakeOutline', avatarColor: '#fff', bgColor: '#0085FF' },
        CH2: { icon: 'mdiHomeOutline', avatarColor: '#fff', bgColor: '#9A62FD' },
        CH3: { icon: 'mdiStarOutline', avatarColor: '#fff', bgColor: '#FFB400' },
        CH4: { icon: 'mdiStarFourPointsOutline', avatarColor: '#fff', bgColor: '#FF821A' },
        CH5: { icon: 'mdiPuzzleOutline', avatarColor: '#fff', bgColor: '#56CA00' },
        CH6: { icon: 'mdiAirballoonOutline', avatarColor: '#fff', bgColor: '#16B1FF' },
        CH7: { icon: 'mdiWeatherSunny', avatarColor: '#fff', bgColor: '#C7B200' },
        CH8: { icon: 'mdiAlienOutline', avatarColor: '#fff', bgColor: '#FF4C51' },
        CH9: { icon: 'mdiAnchor', avatarColor: '#fff', bgColor: '#87ADBD' },
        CH10: { icon: 'mdiPrinter', avatarColor: '#fff', bgColor: '#28243D' },
        CH11: { icon: 'mdiMusic', avatarColor: '#fff', bgColor: '#0051E9' },
        CH12: { icon: 'mdiMovieOpenOutline', avatarColor: '#fff', bgColor: '#00AF7E' },
      }
      const selectedChar = charMap[charCode]

      return selectedChar
    },
    async exportCsv() {
      const file = await this.getCsv()
      if (file) {
        saveAs(file)
      }
    },
    async getCsv() {
      this.isLoadingExport = true

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')
      worksheet.columns = [
        { header: 'N° SLM', key: 'slm', width: 20 },
        { header: 'Data da solicitação', key: 'created_at', width: 20 },
        { header: 'Prazo dado pelo solicitante', key: 'requesters_expected_delivery_date', width: 20 },
        { header: 'Finalizada em', key: 'completion_date', width: 20 },
        { header: 'Duração da Solicitação', key: 'days_between_dates', width: 20 },
        { header: 'Previsão de entrega', key: 'expected_delivery_date', width: 25 },
        { header: 'Assunto', key: 'subject', width: 30 },
        { header: 'Categoria', key: 'category', width: 20 },
        { header: 'Solicitante', key: 'created_by_user.name', width: 30 },
        { header: 'Loja', key: 'created_by_user.company.fantasy_name', width: 30 },
        { header: 'Departamento', key: 'created_by_user.occupation_sector.sector.name', width: 30 },
        { header: 'Terceiro', key: 'third_party.name', width: 30 },
        { header: 'Função do terceiro', key: 'third_party.third_party_activity.name', width: 30 },
        { header: 'Responsável', key: 'assigned_to_user.name', width: 30 },
        { header: 'Status', key: 'status', width: 20 },
      ]

      // Aplicar estilos ao cabeçalho
      const headerRow = worksheet.getRow(1)
      headerRow.eachCell(cell => {
        // eslint-disable-next-line no-param-reassign
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '31869B' },
        }
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          color: { argb: 'FFFFFF' },
          bold: true,
        }
        // eslint-disable-next-line no-param-reassign
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
      })

      worksheet.autoFilter = {
        from: 'A1',
        to: 'O1',
      }

      const mapItemToRow = item => ({
        slm: item.slm || '-',
        created_at: this.formatToDayMonthYear(item.created_at) || '-',
        requesters_expected_delivery_date: this.formatDate(item.requesters_expected_delivery_date) || '-',
        completion_date: this.formatDate(item.completion_date) || '-',
        days_between_dates: item.days_between_dates ? `${item.days_between_dates} dias` : 'RESOLVIDA NO MESMO DIA',
        expected_delivery_date: this.formatToDayMonthYear(item.expected_delivery_date) || '-',
        subject: item.subject || '-',
        category: item.category || '-',
        'created_by_user.name': item.created_by_user?.name || '-',
        'created_by_user.company.fantasy_name': item.created_by_user?.company?.fantasy_name || '-',
        'created_by_user.occupation_sector.sector.name': item.created_by_user?.occupation_sector?.sector?.name || '-',
        'assigned_to_user.name': item.assigned_to_user?.name || '-',
        'third_party.name': item.third_party?.name || '-',
        'third_party.third_party_activity.name': item.third_party?.third_party_activity?.name || '-',
        status: item.status || '-',
      })

      this.listOfFilteredItems?.forEach(item => {
        worksheet.addRow(mapItemToRow(item))
      })

      worksheet.columns.forEach(column => {
        column.eachCell(cell => {
          // eslint-disable-next-line no-param-reassign
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
        })
      })

      let blob = null
      try {
        const data = await workbook.xlsx.writeBuffer()
        const now = new Date()
        const formattedDate = `${now.getDate().toString().padStart(2, '0')}_${(now.getMonth() + 1).toString().padStart(2, '0')}_${now.getFullYear()}`
        const formattedTime = `${now.getHours().toString().padStart(2, '0')}.${now.getMinutes().toString().padStart(2, '0')}.${now.getSeconds().toString().padStart(2, '0')}`
        blob = new File(
          [data],
          `Relatório SLM - ${formattedDate} - ${formattedTime}.xlsx`,
          {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        )
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          showConfirmButton: false,
          text: 'Arquivo Excel gerado com sucesso!',
          timer: 1500,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao gerar arquivo!',
          showConfirmButton: false,
          text: 'Ocorreu um erro ao gerar o arquivo Excel.',
          timer: 2500,
        })
        console.error('Error generating the Excel file:', error)
      } finally {
        this.isLoadingExport = false
      }

      return blob
    },

    returnIconOrColorBasedOnSubject(whichOne, subject) {
      if (whichOne === 'icon') {
        const subjectMap = {
          'AÇÃO DE VENDAS': mdiBullhornOutline,
          'BANNER/MATERIAL DIGITAL': mdiCropSquare,
          'PRODUÇÃO DE MATERIAL': mdiImageOutline,
          'PRODUÇÃO DE VÍDEOS OU FOTOS': mdiVideoOutline,
          'COMUNICAÇÃO INTERNA': mdiVoicemail,
          'MENSAGEM TEMPLATE': mdiMessageOutline,
          'ASSINATURA DE E-MAIL': mdiDraw,
        }

        const subjectData = subjectMap[subject]

        return subjectData || ''
      }

      if (whichOne === 'color') {
        const subjectColorMap = {
          'AÇÃO DE VENDAS': '#FFB400',
          'BANNER/MATERIAL DIGITAL': '#9A62FD',
          'PRODUÇÃO DE MATERIAL': '#56CA00',
          'PRODUÇÃO DE VÍDEOS OU FOTOS': '#FF4C51',
          'COMUNICAÇÃO INTERNA': '#EF70FF',
          'MENSAGEM TEMPLATE': '#FFE500',
          'ASSINATURA DE E-MAIL': '#16B1FF',
        }

        const subjectColor = subjectColorMap[subject]

        return subjectColor || '#000000'
      }

      console.error('Invalid value for whichOne:', whichOne)

      return null
    },
    getStatusColor(item = { status: '' }) {
      const { status } = item

      const statusColorMap = {
        'NÃO INICIADA': 'white',
        'EM ANDAMENTO': 'info',
        'EM EXECUÇÃO COM TERCEIRO': 'greenCyan',
        'ENVIADA PARA VALIDAÇÃO': 'warning',
        'ENVIADA AO SETOR DE COMPRAS': 'lightOrange',
        FINALIZADA: 'success',
      }

      return statusColorMap[status] ?? ''
    },
    getInitials(user) {
      if (!user || !user.name) {
        return '-'
      }
      const names = user?.name?.split(' ')
      if (names.length === 1) {
        return names[0].charAt(0).toUpperCase()
      }
      const firstInitial = names[0].charAt(0).toUpperCase()
      const lastInitial = names[names.length - 1].charAt(0).toUpperCase()

      return firstInitial + lastInitial
    },
    async getTableData() {
      try {
        const { data } = await axiosIns.post('/api/v1/solicitations/marketing/filter', {})
        this.itemsTable = data.data
        this.listOfFilteredItems = this.itemsTable
      } catch (error) {
        console.error('erro ao buscar os dados da tabela de solicitação de marketing:', error)
      }
    },
    formatToDayMonthYear(isoDate) {
      const date = new Date(isoDate)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()

      return `${day}/${month}/${year}`
    },
    formatDate(date) {
      if (!date) return '-'
      const [year, month, day] = date?.split('-')

      return `${day}/${month}/${year}`
    },
    openModalFilterSolicitationsReport() {
      this.showModalFilterReports = true
    },
  },
}
</script>

<style>
  .toBeDefined{
    font-weight: 400;
    font-style: italic;
  }
  .badge {
    margin-top: 16px;
    font-size: 13px;
    height: 40px;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
